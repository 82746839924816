<template>
  <div class="login-whole">
    <Nav></Nav>
    <div class="content">
      <div class="head">
        <img src="" alt="" />
        您的位置：<span>首页 </span> >> 登录
      </div>
      <div class="con">
        <div class="title">欢迎登录美美吴江</div>
        <div class="input" v-if="changType">
          <div class="input-line">
            <span>用户名或已绑定手机号：</span>
            <el-input v-model="logoName" placeholder="请输入用户名"></el-input>
          </div>
          <div class="input-line">
            <span>密码：</span>
            <el-input
              v-model="passWord"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </div>
          <div class="input-line">
            <span>校验码：</span>
            <el-input v-model="code" placeholder="请输入校验码"></el-input>
          </div>
        </div>
        <div class="change" v-if="!changType">
          <div class="input-line">
            <span>用户名或已绑定手机号：</span>
            <el-input v-model="logoName" placeholder="请输入用户名"></el-input>
          </div>
          <div class="input-line">
            <span>短信验证码：</span>
            <el-input
              v-model="mobileCode"
              placeholder="请输入短信验证码"
            ></el-input>
          </div>
          <div class="input-lines">
            <span>图形验证码：</span>
            <el-input v-model="code" placeholder="请输入图形验证码"></el-input>
            <el-button @click="onCaptcha" v-if="numberChange"
              >获取验证短信</el-button
            >
            <el-button v-else>{{ numberText }}秒后重发</el-button>
          </div>
        </div>
        <div class="code-img">
          <img :src="codeImg" alt="" class="yan-img" @click="load()" />
          <el-button type="primary" @click="load()">刷新验证码</el-button>
        </div>
        <div class="login-btn">
          <el-button type="primary" @click="onLogin">登录</el-button>
          <el-button type="primary" @click="onClear()">清除</el-button>
        </div>
        <div class="tip">
          <el-button type="text" class="logintype" @click="onChange">
            <span v-if="changType">短信验证码登录</span>
            <span v-if="!changType">账号密码登录</span>
          </el-button>
          <div>
            如果您忘记密码，且已经绑定手机号码，<a
              href="forgetPassword.html"
              target="_blank"
              >点此找回密码</a
            >
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from "@/api/RD";
import sha1 from "js-sha1";
import Nav from "@/components/nav.vue";
import Bottom from "@/components/bottom.vue";
export default {
  name: "Login",
  components: { Nav, Bottom },
  data() {
    return {
      logoName: "",
      passWord: "",
      code: "",
      changType: true, // 登录的方式
      mobileCode: "", //短信验证码
      numberChange: true, // 验证码的显示
      numberText: 50,
      codeImg: "", // 验证码路径
      logined: false, // 是否登录
    };
  },
  created() {
    this.reload();
    this.load();
  },
  methods: {
    reload() {
      var thiz = this;
      RD.pure()
        .user()
        .sync()
        .then((data) => {
          thiz.logined = true;
          //   if (data.approveStatusName == '等待审批') {
          //     thiz.noUpload = false
          //   } else if (data.approveStatusName == '已通过' && data.upPicStores.length != 0) {
          //     thiz.noUpload = true
          //     thiz.isSelect()
          //   } else if (data.approveStatusName == '已通过' && data.upVideoStores.length != 0) {
          //     thiz.noUpload = true
          //     thiz.isSelect()
          //   }
          //   thiz.syncData = data
        })
        .catch(() => {
          //   thiz.loginState = 1
        });
    },
    load() {
      var thiz = this;
      thiz.codeImg = RD.user().icodeImage_URL() + "?" + new Date().getTime();
    },
    onChange() {
      this.changType = !this.changType;
    },
    // 登录
    onLogin() {
      var thiz = this;
      thiz.loading = true;
      if (thiz.logoName == "") {
        thiz.$message("用户名不能为空");
        return;
      }
      if (thiz.passWord == "") {
        thiz.$message("密码不能为空");
        return;
      }
      if (thiz.code) {
        RD.pure()
          .user()
          .login(thiz.code, thiz.logoName, sha1(thiz.passWord))
          .then(() => {
            thiz.logined = true;
            thiz.code = "";
            thiz.loading = false;
            thiz.$rtm.loginStatus = true;
            // thiz.load();
            // this.syncfresh();
            // thiz.logoedShow = false;
            this.$router.push("/index.html");
            // 检查密码强度的弹框
            // location.reload();
          })
          .catch((error) => {
            thiz.load();
            thiz.$message(error.msg);
            thiz.code = "";
            thiz.loading = false;
          });
      } else {
        thiz.$message("请输入验证码再登录");
      }
    },
    // 清除
    onClear() {},
    // 获取验证码
    onCaptcha() {
      var thiz = this;
      if (thiz.logoName == "") {
        this.$notify({
          title: "提示",
          type: "warning",
          message: "请填写注册手机号",
        });
        return;
      }
      var mobile = /^(?:(?:\+|00)86)?1[3-9]\d{9}$/;
      if (thiz.logoName != "" && !mobile.test(thiz.logoName)) {
        thiz.$alert("手机号不合法, 请重新填写");
        return;
      }

      if (thiz.code == "") {
        this.$notify({
          title: "提示",
          type: "warning",
          message: "请填写图形验证码",
        });
        return;
      }
      RD.pure()
        .user()
        .requestSMSForLogin(thiz.code, thiz.logoName)
        .then(() => {
          thiz.numberChange = false;
          setInterval(() => {
            thiz.numberText--;
            if (thiz.numberText == 0) {
              thiz.numberChange = true;
              thiz.numberText = 50;
            }
          }, 1000);
          thiz.$message("手机发送验证码成功，请注意查收");
        })
        .catch((error) => {
          thiz.$alert(error.msg);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.login-whole {
  width: 100%;
  .content {
    width: 120rem;
    margin: 0 auto;
    min-height: calc(100vh - 47.89rem);
    .head {
      height: 40px;
      background-color: #dfdfdf;
      line-height: 40px;
      white-space: nowrap;
      text-align: left;
      clear: both;
      display: flex;
      font-size: 14px;
      img {
        width: 15px;
      }
      span {
        color: red;
      }
    }
    .con {
      background-color: #efefef;
      .title {
        margin: 0px 30px 30px 0;
        font-size: 30px;
        color: black;
        text-indent: 2em;
      }
      .input-line {
        margin: 10px 170px 10px 0;
        font-size: 16px;
        line-height: 25px;
        span {
          display: inline-block;
          width: 210px;
          text-align: right;
        }
        .el-input {
          width: 200px;
          font-size: 16px;
          line-height: 25px;
        }
      }

      .input-lines {
        width: 54rem;
        margin: 10px auto;
        font-size: 16px;
        line-height: 25px;
        span {
          display: inline-block;
          width: 160px;
          text-align: right;
        }
        .el-input {
          width: 200px;
          font-size: 16px;
          line-height: 25px;
        }
      }
      .code-img {
        img {
          width: 150px;
          height: 50px;
          object-fit: cover;
          line-height: 50px;
          vertical-align: middle;
        }
        .el-button {
          margin-left: 20px;
        }
      }
      .login-btn {
        margin: 20px 0;
      }
      .tip {
        padding-bottom: 10px;
        div {
          margin: 10px 170px 20px 0;
          font-size: 16px;
          line-height: 25px;
          a {
            color: red;
          }
        }
      }
    }
  }
}
</style>